import React, { Fragment, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import QRCode from 'react-qr-code';
import { Stack, Typography } from '@mui/material';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';
import { FaWineGlassAlt, FaThermometerHalf, FaPhoneAlt, FaFacebookF, FaInstagram, FaTelegramPlane, FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { MdLocationOn } from "react-icons/md";

export const batchFields = [
    "id",
    { barcodes: ["id", "code"] },
    {
        customer: [
            "code",
            "name",
            {
                operation: `isMobileHidden(code: WAYBILL)`,
                fields: [],
                variables: {
                },
            },
            {
                zone: ["id", "code", "name"]
            },
            {
                subzone: ["id", "code", "name"]
            },
            "phone",
            "mobile"
        ],
    },
];

export const shipmentFields = [
    {
        data: [
            "code",
            "recipientName",
            "recipientAddress",
            "recipientMobile",
            "recipientPhone",
            "description",
            "weight",
            "piecesCount",
            "totalAmount",
            "senderName",
            "senderPhone",
            "senderMobile",
            "refNumber",
            "notes",
            "date",
            {
                type: ["name", "code"],
            },
            {
                originBranch: ["name"],
            },
            {
                customer: [
                    "code",
                    {
                        operation: `isMobileHidden(code: WAYBILL)`,
                        fields: [],
                        variables: {
                        },
                    },
                ],
            },
            {
                openable: ["code"],
            },

            {
                recipientZone: ["name"],
            },
            {
                recipientSubzone: ["name"],
            },
            {
                senderZone: ["name"],
            },
            {
                senderSubzone: ["name"],
            },
        ],
    },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
    header: `${PREFIX}-header`,
    stickyCode: `${PREFIX}-stickyCode`,
    line: `${PREFIX}-line`,
    logoImg: `${PREFIX}-logoImg`,
    direction: `${PREFIX}-direction`,
    bold: `${PREFIX}-bold`,
    fromStyle: `${PREFIX}-fromStyle`,
    toStyle: `${PREFIX}-toStyle`,
    borderRight: `${PREFIX}-borderRight`,
    capitalize: `${PREFIX}-capitalize`,
    hideSenderPhone: `${PREFIX}-hideSenderPhone`,
    customFontSize: `${PREFIX}-customFontSize`,
    printSticky: `${PREFIX}-printSticky`,
    rotate: `${PREFIX}-rotate`,
    shipmentDetails: `${PREFIX}-shipmentDetails`,
    tableData: `${PREFIX}-tableData`,
    refNumber: `${PREFIX}-refNumber`,
    customWidth: `${PREFIX}-customWidth`,
    notes: `${PREFIX}-notes`,
    footer: `${PREFIX}-footer`,
    customLines: `${PREFIX}-customLines`,
    details: `${PREFIX}-details`,
    borderLeft: `${PREFIX}-borderLeft`,
    phoneStyle: `${PREFIX}-phoneStyle`,
};
export const Root = styled("div")(({ theme }) => ({
    fontSize: "10px",
    [`& p`]: {
        fontSize: "10px"
    },
    [`& .${classes.printSticky}`]: {
        pageBreakAfter: "always",
        position: "relative",
        pageBreakInside: "avoid",
        width: "calc(10cm - (2px + 1mm))",
        height: "calc(15cm - (2px + 2mm))",
        overflow: "hidden",
        fontSize: 11,
        color: "black",
        boxSizing: "border-box",
        lineHeight: "1.2",
        padding: theme.spacing(2)
    },
    [`& .${classes.header}`]: {
        height: "1.9cm",
        [`& p`]: {
            fontSize: "16px !important"
        },
    },
    [`& .${classes.logoImg}`]: {
        maxHeight: "55%",
        maxWidth: "fit-content"
    },
    [`& .${classes.bold}`]: {
        fontWeight: 700,
        [`& p`]: {
            fontWeight: 700,
        },
    },
    [`& .${classes.capitalize}`]: {
        textTransform: "capitalize"
    },
    [`& .${classes.borderRight}`]: {
        borderRight: "1px solid #000",
    },
    [`& .${classes.borderLeft}`]: {
        borderLeft: "1px solid #000",
    },
    [`& .${classes.hideSenderPhone}`]: {
        display: "none !important",
    },
    [`& .${classes.stickyCode}`]: {
        fontFamily: "'Libre Barcode 39 Text'",
        overflow: "hidden",
        fontSize: 45,
        height: "1.4cm"
    },
    [`& .${classes.line}`]: {
        borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    [`& .${classes.details}`]: {
        border: `1px solid ${theme.palette.background.default}`,
    },
    [`& .${classes.phoneStyle}`]: {
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        background: "#000"
    },
    [`& .${classes.customFontSize}`]: {
        fontSize: "14px"
    },
}));


function createDynamicStyles() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const styles = `
    @page {
        size: 10cm 15cm; 
        margin: 1mm !important;
        overflow: hidden;
    }
    * {
            color: black;
    }
    body {
            background: #fff !important
    }
    `;

    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }

    document.head.appendChild(style);
}

const Template10X15 = (props) => {
    const {
        loading,
        validData,
        settingsLoad,
        parsedData,
        isBatch,
        customer,
        currency,
        LogoImg
    } = props

    const { t } = useTranslation()

    useEffect(() => {
        createDynamicStyles()
    }, [])

    return (
        <Fragment>
            {loading || (!loading && !validData) || settingsLoad ? (
                <>
                    <EmptyTableMessage
                        loading={loading}
                        message={t("noShipmentWithCode")}
                    />
                </>
            ) : (
                parsedData.map((shipment, index) => {
                    const rtsShipment = shipment?.type?.code === "RTS";
                    return (
                        <Root key={index}>
                            <div
                                className={clsx({
                                    [classes.printSticky]: !loading && validData,
                                })}
                                style={{ lineHeight: "1.2 !important" }}
                            >
                                {/* Header */}
                                <Stack
                                    justifyContent={"space-between"}
                                    direction={"row"}
                                    alignItems={"center"}
                                    className={clsx(
                                        classes.header,
                                        classes.bold
                                    )}
                                >
                                    <Stack width={"50%"}>
                                        <Typography>بوليصة الشحن</Typography>
                                        <Typography>Bill Of LADING</Typography>
                                    </Stack>
                                    <Stack
                                        width={"50%"}
                                        pt={0.5}
                                        height={"100%"}
                                        fontWeight={"bold"}
                                        spacing={0.5}
                                        justifyContent={"center"}
                                        alignItems={"flex-end"}
                                    >
                                        <LogoImg forceLight={true} className={classes.logoImg} />
                                    </Stack>
                                </Stack>
                                {/* End Header */}

                                <Stack className={classes.details}>
                                    <Stack direction={"row"} className={classes.line}>
                                        <StackOfTitle title={"description"} />
                                        <StackOfValue value={shipment?.description} />
                                    </Stack>
                                    <Stack direction={"row"} className={classes.line}>
                                        <Stack direction={"row"} width={"50%"}>
                                            <StackOfTitle title={"pieces"} />
                                            <StackOfValue value={shipment?.piecesCount} />
                                        </Stack>
                                        <Stack direction={"row"} width={"50%"}>
                                            <StackOfTitle title={"cod"} ifBorderLeft />
                                            <StackOfValue
                                                ifComponent
                                                value={
                                                    <Stack direction={"row"} spacing={0.3}>
                                                        <Typography>
                                                            {rtsShipment ? 0 : shipment?.totalAmount}
                                                        </Typography>
                                                        <Typography>
                                                            {!isBatch && currency}
                                                        </Typography>
                                                    </Stack>
                                                }
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} className={classes.line}>
                                        <Stack direction={"row"} width={"50%"}>
                                            <StackOfTitle title={"recipient"} />
                                            <StackOfValue value={shipment?.recipientName} />
                                        </Stack>
                                        <Stack direction={"row"} width={"50%"}>
                                            <StackOfTitle title={"phone"} ifBorderLeft />
                                            <StackOfValue
                                                textHeight={"40px"}
                                                value={
                                                    isBatch ? ' ' : (shipment?.recipientMobile &&
                                                        shipment?.recipientPhone
                                                        ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                                                        : shipment?.recipientMobile ??
                                                        shipment?.recipientPhone)
                                                } />
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} className={classes.line}>
                                        <Stack direction={"row"} width={"50%"}>
                                            <StackOfTitle title={"zone"} />
                                            <StackOfValue value={shipment?.recipientZone?.name} />
                                        </Stack>
                                        <Stack direction={"row"} width={"50%"}>
                                            <StackOfTitle title={"address"} ifBorderLeft />
                                            <StackOfValue value={shipment?.recipientAddress} />
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} className={classes.line}>
                                        <StackOfTitle title={"sender"} />
                                        <StackOfValue value={customer ? customer.name : shipment?.senderName} />
                                    </Stack>
                                    <Stack direction={"row"}>
                                        <StackOfTitle title={"notes"} />
                                        <StackOfValue value={shipment?.notes} />
                                    </Stack>
                                </Stack>
                                <Stack my={1} spacing={0.5} alignItems={"center"}>
                                    <Stack direction={"row"}>
                                        <FaThermometerHalf size={18} />
                                        <FaWineGlassAlt size={18} />
                                    </Stack>
                                    {/* Barcode */}
                                    <Stack
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        className={classes.stickyCode}
                                    >
                                        *{shipment.code}*
                                    </Stack>
                                    {/* End Barcode */}

                                    <Stack
                                        height={"100%"}
                                        width={"17%"}
                                        direction={"row"}
                                    >
                                        <QRCode size={900} value={shipment.code} style={{ height: "100%" }} />
                                    </Stack>
                                    <Stack direction={"row"} width={"100%"} justifyContent={"space-evenly"}>
                                        <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                                            <FaFacebookF size={13} />
                                            <FaXTwitter size={13} />
                                            <FaInstagram size={13} />
                                            <FaTelegramPlane size={13} />
                                            <FaLinkedinIn size={13} />
                                            <Typography px={1} className={clsx(classes.customFontSize, classes.borderLeft)}>
                                                evo
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                                            <Stack justifyContent={"center"} alignItems={"center"} className={classes.phoneStyle}>
                                                <FaPhoneAlt fill='#fff' size={10} />
                                            </Stack>
                                            <Typography className={classes.customFontSize}>
                                                0946444543
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} width={"100%"} justifyContent={"space-evenly"}>
                                        <Stack>
                                            <Typography>
                                                شــكــرا لاخــتــيــاركم إيــفــو
                                            </Typography>
                                            <Typography>
                                                Thank you for choosing Evo
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                                            <Stack>
                                                <Typography>
                                                    سوق الجمعه _طرابلس
                                                </Typography>
                                                <Typography>
                                                    Souq Al-Jumaa - Tripoli
                                                </Typography>
                                            </Stack>
                                            <Stack justifyContent={"center"} alignItems={"center"} className={classes.phoneStyle}>
                                                <MdLocationOn fill='#fff' size={10} />
                                            </Stack>
                                        </Stack>

                                    </Stack>
                                </Stack>

                            </div>
                        </Root>
                    )
                })
            )}
        </Fragment>
    )
}

export default Template10X15

const StackOfTitle = ({ title, ifBorderLeft }) => {
    const { t } = useTranslation()
    return (
        <Stack
            className={clsx(classes.borderRight, classes.bold, { [classes.borderLeft]: ifBorderLeft })}
            minWidth={"55px"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Typography>{t(title)}</Typography>
        </Stack>
    )
}

const StackOfValue = ({ value, ifComponent, textHeight }) => {
    return (
        <Stack
            width={"-webkit-fill-available"}
            justifyContent={"center"}
            alignItems={"center"}
            px={0.5}
            height={"40px"}
            overflow={"hidden"}
        >
            {ifComponent ? value :
                <Typography
                    textAlign={"center"}
                    className={classes.customLines}
                    height={textHeight ?? "15px"}
                    overflow={"hidden"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    {value}
                </Typography>
            }
        </Stack>
    )
}